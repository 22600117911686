import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"

import CTABlock from "../../components/blocks/cta_block"

const GovernancePage = ({ data }) => {
  const page = data.wagtail.page

  let ancestor = { children: [], button: {} }
  data.wagtail.page.ancestors.forEach((item) => {
    if ("children" in item) {
      ancestor = item
    }
  })

  return (
    <>
      <Layout
        isContent={true}
        subMenu={ancestor.children}
        button={ancestor.button}
        parent={"about"}
        siteTitle={page.title}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/about">About</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="governance-info-area">
          <div className="tab-content-area">
            <div className="container simple-page">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <h1>{page.title}</h1>
                      <p>{page.descriptionSimple}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="c-title-content mb-80"
                        dangerouslySetInnerHTML={{ __html: page.bodySafe }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CTABlock data={page.fullContent[0]} />
      </Layout>
    </>
  )
}

GovernancePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    wagtail {
      page(slug: "governance") {
        ... on AboutProfilePage {
          id
          title
          slug
          descriptionSimple
          bodySafe
          body
          fullContent {
            ... on CTABlock {
              id
              title
              description
              orientation
              url
              urlName
              image {
                src
                width
                height
              }
            }
          }
        }
        ancestors {
          ... on AboutPage {
            children {
              title
              slug
              ... on SimplePage {
                live
                isProtected
              }
              ... on AboutProfilePage {
                live
                isProtected
              }
            }
            button {
              text
              name
              url
            }
          }
        }
      }
    }
  }
`

export default GovernancePage
